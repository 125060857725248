<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                <div class="row">
                    <div class="col-sm-1 d-flex flex-row justify-content-end">
                        <a href="/">
                            <img src="assets/images/Mirnah_bottom_left.png" alt="Header Language" height="16">
                        </a>
                    </div>
                    <div class="col-sm-4 d-flex flex-row justify-content-start pt-1">
                        {{year}} © Mirnah.
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    <div class="dropdown d-inline-block float-right" ngbDropdown placement="top">
                        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle
                            style="height:auto; padding:0">
                            <!-- <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> -->
                            <span class="ml-1 text-dark">{{countryName}}</span>
                            <!-- <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16"> -->
                            <!-- <span *ngIf="flagvalue == undefined" class="ml-1 text-dark">English</span> -->
                        </button>
                        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                            <!-- item-->
                            <a href="javascript:void(0);" class="dropdown-item notify-item"
                                *ngFor="let item of listLang" (click)="setLanguage(item.text, item.lang, item.flag)"
                                [ngClass]="{'active': cookieValue === item.lang}">
                                <!-- <img src="{{item.flag}}" alt="user-image" class="mr-1" height="12">  -->
                                <span class="align-middle">{{item.text}}</span>
                            </a>
                            <!-- item-->
                        </div>
                    </div>
                    &nbsp;
                    Mirnah Technologies Limited
                </div>
            </div>
        </div>
    </div>
</footer>