<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo.png" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="17">
          </span>
        </a>

        <a href="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.svg" alt="" height="22">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="19">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- App Search-->
      <form class="app-search d-none d-lg-block">
        <div class="position-relative">
          <input type="text" class="form-control" placeholder="{{'HEADER.SEARCH' | translate}}">
          <span class="bx bx-search-alt"></span>
        </div>
      </form>

      <div class="dropdown-mega d-none d-lg-block ms-2" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle data-toggle="dropdown" aria-haspopup="false"
          aria-expanded="false">
          {{ 'HEADER.MEGA_MENU' | translate}}
          <i class="mdi mdi-chevron-down"></i>
        </button>
        <div class="dropdown-menu dropdown-megamenu" ngbDropdownMenu>
          <div class="row">
            <div class="col-sm-8">

              <div class="row">
                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0"> {{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.APPLICATIONS.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.ECOMMERCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CALENDAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.EMAIL' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.PROJECTS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.TASKS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.APPLICATIONS.CONTACTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-md-4">
                  <h5 class="font-size-14 mt-0">{{ 'HEADER.EXTRA_PAGES.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.LIGHT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMPACT_SIDEBAR' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.HORIZONTAL_LAYOUT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.MAINTENANCE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.COMING SOON' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.TIMELINE' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.EXTRA_PAGES.FAQ' | translate}}</a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="row">
                <div class="col-sm-6">
                  <h5 class="font-size-14 mt-0"> {{ 'HEADER.UI.TITLE' | translate}}</h5>
                  <ul class="list-unstyled megamenu-list">
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.LIGHTBOX' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RANGE_SLIDER' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.SWEET_ALERT' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.RATING' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.FORMS' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.TABLES' | translate}}</a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">{{ 'HEADER.UI.CHARTS' | translate}}</a>
                    </li>
                  </ul>
                </div>

                <div class="col-sm-5">
                  <div>
                    <img src="assets/images/megamenu-img.png" alt="" class="img-fluid mx-auto d-block">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex">

      <div class="dropdown d-inline-block d-lg-none ms-2" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-search-dropdown" data-toggle="dropdown"
          aria-haspopup="true" ngbDropdownToggle aria-expanded="false">
          <i class="mdi mdi-magnify"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0" ngbDropdownMenu
          aria-labelledby="page-header-search-dropdown">

          <form class="p-3">
            <div class="m-0">
              <div class="input-group">
                <input type="text" class="form-control" placeholder="Search ..." aria-label="Recipient's username">
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> <span
            class="ms-1">{{countryName}}</span>
          <img *ngIf="flagvalue === undefined" src="{{valueset}}" alt="Header Language" height="16">
          <span *ngIf="flagvalue === undefined" class="ms-1">English</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
            (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
            <img src="{{item.flag}}" alt="user-image" class="me-1" height="12"> <span
              class="align-middle">{{item.text}}</span>
          </a>
          <!-- item-->
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" ngbDropdownToggle>
          <i class="bx bx-customize"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end" ngbDropdownMenu>
          <div class="px-lg-2">
            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/github.png" alt="Github">
                  <span>GitHub</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/bitbucket.png" alt="bitbucket">
                  <span>Bitbucket</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dribbble.png" alt="dribbble">
                  <span>Dribbble</span>
                </a>
              </div>
            </div>

            <div class="row no-gutters">
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/dropbox.png" alt="dropbox">
                  <span>Dropbox</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp">
                  <span>Mail Chimp</span>
                </a>
              </div>
              <div class="col">
                <a class="dropdown-icon-item" href="javascript: void(0);">
                  <img src="assets/images/brands/slack.png" alt="slack">
                  <span>Slack</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item noti-icon" id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="bx bx-bell bx-tada"></i>
          <span class="badge bg-danger rounded-pill">3</span>
        </button>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
          aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0">{{ 'HEADER.NOTIFICATIONS.TITLE' | translate}} </h6>
              </div>
              <div class="col-auto">
                <a href="javascript: void(0);" class="small">{{ 'HEADER.NOTIFICATIONS.VIEW_ALL' | translate}}</a>
              </div>
            </div>
          </div>
          <ngx-simplebar style="position: relative; height: 230px;">
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-primary rounded-circle font-size-16">
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FIRST.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>
                      {{ 'HEADER.NOTIFICATIONS.FIRST.TIME' | translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.SECOND.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.SECOND.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs me-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="bx bx-badge-check"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.THIRD.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.THIRD.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
            <a href="javascript: void(0);" class="text-reset notification-item">
              <div class="media">
                <img src="assets/images/users/avatar-4.jpg" class="me-3 rounded-circle avatar-xs" alt="user-pic">
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TITLE' | translate}}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ 'HEADER.NOTIFICATIONS.FOUR.TEXT' | translate}}</p>
                    <p class="mb-0"><i class="mdi mdi-clock-outline"></i>{{ 'HEADER.NOTIFICATIONS.FOUR.TIME' |
                      translate}}</p>
                  </div>
                </div>
              </div>
            </a>
          </ngx-simplebar>
          <div class="p-2 border-top d-grid">
            <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
              <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{{
                'HEADER.NOTIFICATIONS.LOAD_MORE' | translate}}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" ngbDropdownToggle id="page-header-user-dropdown">
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">Henry</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" routerLink="/contacts/profile"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.PROFILE' | translate}}</a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle me-1"></i>{{ 'HEADER.LOGIN.MY_WALLET' | translate}}</a>
          <a class="dropdown-item d-block" href="javascript: void(0);"><span
              class="badge bg-success float-end">11</span><i class="bx bx-wrench font-size-16 align-middle me-1"></i>{{
            'HEADER.LOGIN.SETTINGS' | translate}} </a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-lock-open font-size-16 align-middle me-1"></i>
            {{ 'HEADER.LOGIN.LOCK_SCREEN' | translate}}</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            {{ 'HEADER.LOGIN.LOGOUT' | translate}}</a>
        </div>
      </div>

      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>

    </div>
  </div>
</header>